import React, { useState } from "react";
import {
  useAddUpdatePreferences,
  useMember,
  usePreferences,
} from "../apis/Member";
import { Link, useParams } from "react-router-dom";
import FullScreenLoader from "../component/loaders/fullscreen";
import TagsTwo from "../component/select/tagsTwo";
import { useCountries } from "../apis/Locations.";

const PreferencesInfo = () => {
  const memberPreferences = useMember();

  const [height, setHeight] = useState(
    memberPreferences.data?.data.data[0].preferences?.height?.height || 0
  );
  const [hUnit, setHUnit] = useState(
    memberPreferences.data?.data.data[0].preferences?.height?.unit || "FT"
  );
  const [weight, setWeight] = useState(
    memberPreferences.data?.data.data[0].preferences?.weight?.weight || 0
  );
  const [wUnit, setWUnit] = useState(
    memberPreferences.data?.data.data[0].preferences?.weight?.unit || "KG"
  );
  const [bloodType, setBloodType] = useState(
    memberPreferences.data?.data.data[0].preferences?.blood?.id || ""
  );
  const [bodyType, setBodyType] = useState(
    memberPreferences.data?.data.data[0].preferences?.body?.id || ""
  );
  const [complextion, setComplextion] = useState(
    memberPreferences.data?.data.data[0].preferences?.complexion?.id || ""
  );
  const [disability, setDisability] = useState(
    memberPreferences.data?.data.data[0].preferences?.disabilities?.id || ""
  );
  const [education, setEducation] = useState(
    memberPreferences.data?.data.data[0].preferences?.education?.id || ""
  );
  const [job, setJob] = useState(
    memberPreferences.data?.data.data[0].preferences?.job?.id || ""
  );
  const [employment, setEmployment] = useState(
    memberPreferences.data?.data.data[0].preferences?.employment?.id || ""
  );
  const [ethincity, setEthincity] = useState(
    memberPreferences.data?.data.data[0].preferences?.race?.id || ""
  );
  const [eye, setEye] = useState(
    memberPreferences.data?.data.data[0].preferences?.eye?.id || ""
  );
  const [hair, setHair] = useState(
    memberPreferences.data?.data.data[0].preferences?.hair?.id || ""
  );
  const [marital, setMarital] = useState(
    memberPreferences.data?.data.data[0].preferences?.marital?.id || ""
  );
  const [kids, setKids] = useState("1");
  const [smoker, setSmoker] = useState(
    memberPreferences.data?.data.data[0].preferences?.is_smoker?.toString() ||
      "1"
  );
  const [drinker, setDrinker] = useState(
    memberPreferences.data?.data.data[0].preferences?.is_drinker?.toString() ||
      "1"
  );
  const [country, setCountry] = useState(
    memberPreferences.data?.data.data[0].preferences?.country?.id || ""
  );
  const [hobbies, setHobbies] = useState(
    memberPreferences.data?.data.data[0].preferences?.hobbies?.split(",") || []
  );

  const { type } = useParams();

  const uinfo = localStorage.getItem("uinf");

  const { mutate, isLoading } = useAddUpdatePreferences();

  const preferences = usePreferences();
  const countries = useCountries();

  if (preferences.isLoading || countries.isLoading)
    return <FullScreenLoader size={200} center={true} />;
  if (preferences.isError || countries.isError) return <h6>Error</h6>;

  const onSubmit = async (e) => {
    e.preventDefault();
    mutate({
      type,
      height,
      hUnit,
      weight,
      wUnit,
      smoker,
      drinker,
      hobbies,
      disability,
      complextion,
      education,
      eye,
      marital,
      kids,
      employment,
      job,
      bloodType,
      bodyType,
      hair,
      ethincity,
      country,
    });
  };

  return (
    <>
      <div className="">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Preferences Information
            </h5>
            <Link
              type="button"
              className="btn-close"
              to={`/member/${JSON.parse(uinfo).id}`}
            ></Link>
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit}>
              <div className="banner__list">
                <div className="row align-items-center row-cols-1">
                  <div className="col">
                    <div className="row g-3">
                      <div className="col-12 col-md-6">
                        <div className="row">
                          <label>Height</label>
                          <div className="col-7 col-md-9">
                            <div className="banner__inputlist">
                              <input
                                type="number"
                                className="my-form-control"
                                value={height}
                                onChange={(e) => setHeight(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="banner__inputlist">
                              <select
                                name="height"
                                className="my-form-control"
                                value={hUnit}
                                onChange={(e) => setHUnit(e.target.value)}
                              >
                                <option value="ft">FT</option>
                                <option value="inch">INCH</option>
                                <option value="cm">CM</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="row">
                          <label>Weight</label>
                          <div className="col-7 col-md-9">
                            <div className="banner__inputlist">
                              <input
                                type="number"
                                className="my-form-control"
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="banner__inputlist">
                              <select
                                name="weight"
                                className="my-form-control"
                                value={wUnit}
                                onChange={(e) => setWUnit(e.target.value)}
                              >
                                <option value="kg">KG</option>
                                <option value="lb">LB</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <label>Country Of Birth</label>
                        <div className="banner__inputlist">
                          <select
                            name="blood_type"
                            className="my-form-control"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            <option value="">Choose Country Of Birth</option>
                            {countries.data.data.data.map((b) => (
                              <option value={b.id} key={"cob-" + b.id}>
                                {b.location}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        <label>Blood Type</label>
                        <div className="banner__inputlist">
                          <select
                            name="blood_type"
                            className="my-form-control"
                            value={
                              bloodType
                              // ? setBloodType(
                              //     preferences.data.data.data.blood[0].id
                              //   )
                              // : bloodType
                            }
                            onChange={(e) => setBloodType(e.target.value)}
                          >
                            <option value="">Choose Blood Type</option>

                            {preferences.data.data.data.blood.map((b) => (
                              <option value={b.id} key={"btype-" + b.id}>
                                {b.blood}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        <label>Body Type</label>
                        <div className="banner__inputlist">
                          <select
                            name="body_type"
                            className="my-form-control"
                            value={bodyType}
                            onChange={(e) => setBodyType(e.target.value)}
                          >
                            <option value="">Choose Body Type</option>
                            {preferences.data.data.data.body.map((b) => (
                              <option value={b.id} key={"botype-" + b.id}>
                                {b.body}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        <label>Complextion</label>
                        <div className="banner__inputlist">
                          <select
                            name="complextion"
                            className="my-form-control"
                            value={complextion}
                            onChange={(e) => setComplextion(e.target.value)}
                          >
                            <option value="">Choose Complextion</option>
                            {preferences.data.data.data.complextion.map((b) => (
                              <option value={b.id} key={"complextion-" + b.id}>
                                {b.complextion}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        <label>Disability</label>
                        <div className="banner__inputlist">
                          <select
                            name="disability"
                            className="my-form-control"
                            value={disability}
                            onChange={(e) => setDisability(e.target.value)}
                          >
                            <option value="">Choose Disability</option>
                            {preferences.data.data.data.disability.map((b) => (
                              <option value={b.id} key={"disability-" + b.id}>
                                {b.disability}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Education</label>
                        <div className="banner__inputlist">
                          <select
                            name="education"
                            className="my-form-control"
                            value={education}
                            onChange={(e) => setEducation(e.target.value)}
                          >
                            <option value="">Choose Education</option>

                            {preferences.data.data.data.education.map((b) => (
                              <option value={b.id} key={"education-" + b.id}>
                                {b.education}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Job</label>
                        <div className="banner__inputlist">
                          <select
                            name="job"
                            className="my-form-control"
                            value={job}
                            onChange={(e) => setJob(e.target.value)}
                          >
                            <option value="">Choose Job</option>

                            {preferences.data.data.data.job.map((b) => (
                              <option value={b.id} key={"job-" + b.id}>
                                {b.job}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Employment</label>
                        <div className="banner__inputlist">
                          <select
                            name="employment"
                            className="my-form-control"
                            value={employment}
                            onChange={(e) => setEmployment(e.target.value)}
                          >
                            <option value="">Choose Employment</option>

                            {preferences.data.data.data.employment.map((b) => (
                              <option value={b.id} key={"employment-" + b.id}>
                                {b.employment}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Ethincity</label>
                        <div className="banner__inputlist">
                          <select
                            name="ethincity"
                            className="my-form-control"
                            value={ethincity}
                            onChange={(e) => setEthincity(e.target.value)}
                          >
                            <option value="">Choose Ethincity</option>

                            {preferences.data.data.data.race.map((b) => (
                              <option value={b.id} key={"ethincity-" + b.id}>
                                {b.race}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Eye</label>
                        <div className="banner__inputlist">
                          <select
                            name="eye"
                            className="my-form-control"
                            value={eye}
                            onChange={(e) => setEye(e.target.value)}
                          >
                            <option value="">Choose Eye</option>
                            {preferences.data.data.data.eye.map((b) => (
                              <option value={b.id} key={"eye-" + b.id}>
                                {b.eye}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Hair</label>
                        <div className="banner__inputlist">
                          <select
                            name="hair"
                            className="my-form-control"
                            value={hair}
                            onChange={(e) => setHair(e.target.value)}
                          >
                            <option value="">Choose Hair</option>
                            {preferences.data.data.data.hair.map((b) => (
                              <option value={b.id} key={"hair-" + b.id}>
                                {b.hair}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Marital</label>
                        <div className="banner__inputlist">
                          <select
                            name="marital"
                            className="my-form-control"
                            value={marital}
                            onChange={(e) => setMarital(e.target.value)}
                          >
                            <option value="">Choose Marital</option>
                            {preferences.data.data.data.marital.map((b) => (
                              <option value={b.id} key={"marital-" + b.id}>
                                {b.marital}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Kids</label>
                        <div className="banner__inputlist">
                          <div className="d-flex m-auto">
                            <input
                              type="radio"
                              name="kids"
                              value="1"
                              id="yes"
                              checked={kids === "1"}
                              onChange={(e) => setKids(e.target.value)}
                            />
                            <label className="my-auto" htmlFor="yes">
                              Yes
                            </label>
                          </div>
                          <div className="d-flex m-auto">
                            <input
                              type="radio"
                              name="kids"
                              value="0"
                              id="no"
                              onChange={(e) => setKids(e.target.value)}
                              checked={kids === "0"}
                            />
                            <label className="my-auto" htmlFor="no">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Smoker</label>
                        <div className="banner__inputlist">
                          <div className="d-flex m-auto">
                            <input
                              type="radio"
                              name="smoker"
                              value="1"
                              id="yes"
                              onChange={(e) => setSmoker(e.target.value)}
                              checked={smoker === "1"}
                            />
                            <label className="my-auto" htmlFor="yes">
                              Yes
                            </label>
                          </div>
                          <div className="d-flex m-auto">
                            <input
                              type="radio"
                              name="smoker"
                              value="0"
                              id="no"
                              onChange={(e) => setSmoker(e.target.value)}
                              checked={smoker === "0"}
                            />
                            <label className="my-auto" htmlFor="no">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Drinker</label>
                        <div
                          className="banner__inputlist"
                          onChange={(e) => setDrinker(e.target.value)}
                        >
                          <div className="d-flex m-auto">
                            <input
                              type="radio"
                              name="drinker"
                              value="1"
                              id="yes"
                              checked={drinker === "1"}
                            />
                            <label className="my-auto" htmlFor="yes">
                              Yes
                            </label>
                          </div>
                          <div className="d-flex m-auto">
                            <input
                              type="radio"
                              name="drinker"
                              value="0"
                              id="no"
                              checked={drinker === "0"}
                            />
                            <label className="my-auto" htmlFor="no">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>Hobbies</label>
                        <TagsTwo tags={hobbies} setTags={setHobbies} />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <button
                      type="submit"
                      className="default-btn reverse d-block"
                    >
                      {isLoading ? (
                        <div
                          className="spinner-border spinner-border-sm text-info"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <span>Save</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreferencesInfo;
