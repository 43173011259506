import React, { useState } from "react";
import { useCitiesByCountryId, useCountries } from "../apis/Locations.";
import { useMember, useUpdateMemberProfile } from "../apis/Member";
import { Link } from "react-router-dom";
import FullScreenLoader from "../component/loaders/fullscreen";

const BaseInfo = () => {
  const memberData = useMember();
  const [regCountry, setRegCountry] = useState(
    memberData.data?.data.data[0].country.id || 1
  );
  const [regCity, setRegCity] = useState(memberData.data?.data.data[0].city.id);
  const [regBio, setRegBio] = useState(memberData.data?.data.data[0].bio || "");
  const [regBdate, setRegBdate] = useState(
    memberData.data?.data.data[0].birthdate || ""
  );
  const uinfo = localStorage.getItem("uinf");

  const { mutate: updateMemberProfile, isLoading: updateIsLoading } =
    useUpdateMemberProfile();

  const countries = useCountries();
  const cities = useCitiesByCountryId(regCountry);

  if (memberData.isLoading || countries.isLoading)
    return <FullScreenLoader size={200} center={true} />;
  if (memberData.isError || countries.isError) return <h6>Error</h6>;

  const onSubmit = async (e) => {
    e.preventDefault();
    updateMemberProfile({
      about: regBio,
      birthdate: regBdate,
      country_id: regCountry,
      city_id: regCity,
    });
  };
  const handleCities = (e) => {
    setRegCountry(e.target.value);
  };

  return (
    <>
      <div className="modal-content" style={{ height: "100vh" }}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Base Information
          </h5>
          <Link
            type="button"
            className="btn-close"
            to={`/member/${JSON.parse(uinfo).id}`}
          ></Link>
        </div>
        <div className="modal-body">
          <form onSubmit={onSubmit}>
            <div className="banner__list">
              <div className="row align-items-center row-cols-1">
                <div className="col">
                  <label>Birthday</label>
                  <div className="banner__inputlist">
                    <input
                      type="date"
                      className="my-form-control"
                      value={regBdate}
                      onChange={(e) => setRegBdate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="row g-3">
                    <div className="col-6">
                      <label>Country</label>
                      <div className="banner__inputlist">
                        <select
                          name="countries"
                          className="my-form-control"
                          onChange={(e) => handleCities(e)}
                          value={regCountry}
                        >
                          {countries.data.data.data.map((country) => (
                            <option
                              value={country.id}
                              key={"country-" + country.id}
                            >
                              {country.location}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <label>City</label>
                      <div className="banner__inputlist">
                        <select
                          className={"my-form-control"}
                          onChange={(e) => setRegCity(e.target.value)}
                          value={regCity}
                          disabled={
                            cities === null ||
                            cities.data?.data.data.length === 0
                              ? true
                              : false
                          }
                        >
                          {cities.data?.data.data.map((city) => (
                            <option
                              value={city.id}
                              key={"city-" + city.id}
                              selected={city.id === regCity}
                            >
                              {city.location}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <label>Bio</label>
                  <div className="banner__inputlist">
                    <textarea
                      className="my-form-control ps-1 py-3"
                      rows="10"
                      style={{ height: 150 }}
                      placeholder="Write something about yourself !!"
                      onChange={(e) => setRegBio(e.target.value)}
                      defaultValue={regBio}
                    ></textarea>
                  </div>
                </div>
                <div className="col">
                  <button
                    type="submit"
                    className="default-btn reverse d-block"
                    disabled={updateIsLoading}
                  >
                    {updateIsLoading ? (
                      <div
                        className="spinner-border spinner-border-sm text-info"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BaseInfo;
