import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { twentyFourHoursInMs } from "../global";
import i18n from "../i18n";
import { useNavigate } from "react-router-dom";

const {
  REACT_APP_BASE_URL,
  REACT_APP_GET_ALL_MEMBERS,
  REACT_APP_GET_RANDOM_MEMBERS,
  REACT_APP_GET_MEMBER_BY_ID,
  REACT_APP_GET_ALL_PREFERENCES,
  REACT_APP_UPDATE_MEMBER_PROFILE,
  REACT_APP_ADD_MEMBER_PREFERENCES,
  REACT_APP_UPDATE_MEMBER_PREFERENCES,
  REACT_APP_ADD_MEMBER_GALLERY_IMG,
  REACT_APP_GET_MEMBERS_BY_FILTER,
  REACT_APP_INTERACT,
  REACT_APP_GET_MEMBER_NOTICES,
  REACT_APP_CLOSE_ACCOUNT,
} = process.env;

export const fetchAllMembers = (lng, page) => {
  return axios.get(REACT_APP_BASE_URL + REACT_APP_GET_ALL_MEMBERS + lng, {
    headers: { Authorization: "Bearer " + localStorage.getItem("uts") },
    params: { page: page },
  });
};

export const fetchRandomMembers = (lng) => {
  return axios.get(REACT_APP_BASE_URL + REACT_APP_GET_RANDOM_MEMBERS + lng);
};

export const fetchMemberById = (id, lng) => {
  return axios.get(
    REACT_APP_BASE_URL + REACT_APP_GET_MEMBER_BY_ID + id + "/" + lng,
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("uts") },
    }
  );
};

export const fetchAllPreferences = (lng) => {
  return axios.get(REACT_APP_BASE_URL + REACT_APP_GET_ALL_PREFERENCES + lng, {
    headers: { Authorization: "Bearer " + localStorage.getItem("uts") },
  });
};

export const updateMemberProfile = (data) => {
  const uinfo = localStorage.getItem("uinf");

  return axios.post(
    REACT_APP_BASE_URL + REACT_APP_UPDATE_MEMBER_PROFILE + JSON.parse(uinfo).id,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("uts"),
      },
    }
  );
};

export const addUpdatePreferences = (data) => {
  let reqUrl = "";
  const uinfo = localStorage.getItem("uinf");

  if (data.type === "add") {
    reqUrl = REACT_APP_ADD_MEMBER_PREFERENCES;
  } else {
    reqUrl = REACT_APP_UPDATE_MEMBER_PREFERENCES + JSON.parse(uinfo).id;
  }
  return axios.post(
    REACT_APP_BASE_URL + reqUrl,
    {
      height: data.height,
      height_unit: data.hUnit,
      weight: data.weight,
      weight_unit: data.wUnit,
      smoker: data.smoker,
      drinker: data.drinker,
      hobbies: data.hobbies.toString(),
      disabilities: data.disability,
      complexion: data.complextion,
      education: data.education,
      eye_color: data.eye,
      marital_status: data.marital,
      has_kids: data.kids,
      employment_type: data.employment,
      job: data.job,
      blood_type: data.bloodType,
      body_type: data.bodyType,
      hair_color: data.hair,
      ethnicity: data.ethincity,
      country: data.country,
      member: JSON.parse(uinfo).id,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("uts"),
      },
    }
  );
};

export const addGalleryImg = (data) => {
  return axios.post(
    REACT_APP_BASE_URL + REACT_APP_ADD_MEMBER_GALLERY_IMG,
    { image: data },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("uts"),
      },
    }
  );
};

export const getMembersByFilter = (data) => {
  return axios.post(
    REACT_APP_BASE_URL +
      REACT_APP_GET_MEMBERS_BY_FILTER +
      "?search=" +
      data.page,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("uts"),
      },
    }
  );
};

export const addInteract = (data) => {
  return axios.post(
    REACT_APP_BASE_URL + REACT_APP_INTERACT,
    { member: data.uId, like: Number(!data.isLiked) },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("uts"),
      },
    }
  );
};

export const fetchMemberNotices = (id, lng) => {
  return axios.get(REACT_APP_BASE_URL + REACT_APP_GET_MEMBER_NOTICES + id, {
    headers: { Authorization: "Bearer " + localStorage.getItem("uts") },
  });
};

export const closeAccount = () => {
  const uinfo = localStorage.getItem("uinf");

  return axios.post(
    REACT_APP_BASE_URL + REACT_APP_CLOSE_ACCOUNT,
    { id: JSON.parse(uinfo)?.id },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("uts"),
      },
    }
  );
};

export const useMembers = (page) => {
  return useQuery({
    queryKey: ["members", i18n.language, page],
    queryFn: () => fetchAllMembers(i18n.language, page),
    enabled: true,
    staleTime: Infinity,
    // refetchInterval: 2000,
  });
};

export const useRandomMembers = () => {
  return useQuery({
    queryKey: ["randomMembers", i18n.language],
    queryFn: () => fetchRandomMembers(i18n.language),
    staleTime: twentyFourHoursInMs,
  });
};

export const useMember = () => {
  const uinfo = localStorage.getItem("uinf");

  return useQuery({
    queryKey: ["member", i18n.language],
    queryFn:
      JSON.parse(uinfo) !== null
        ? () => fetchMemberById(JSON.parse(uinfo)?.id, i18n.language)
        : null,
  });
};

export const useMemberView = (id) => {
  return useQuery({
    queryKey: ["member_view_" + id, i18n.language],
    queryFn: () => fetchMemberById(id, i18n.language),
    staleTime: twentyFourHoursInMs,
  });
};

export const usePreferences = () => {
  return useQuery({
    queryKey: ["preferences", i18n.language],
    queryFn: () => fetchAllPreferences(i18n.language),
    staleTime: twentyFourHoursInMs,
  });
};

export const useAddUpdatePreferences = () => {
  const queryClient = useQueryClient();
  const Navigate = useNavigate();
  const uinfo = localStorage.getItem("uinf");

  return useMutation({
    mutationFn: addUpdatePreferences,
    onSuccess: () => {
      queryClient.invalidateQueries(["member", i18n.language]);
      Navigate(`/member/${JSON.parse(uinfo).id}`);
    },
  });
};

export const useUpdateMemberProfile = () => {
  const queryClient = useQueryClient();
  const Navigate = useNavigate();
  const uinfo = localStorage.getItem("uinf");

  return useMutation({
    mutationFn: updateMemberProfile,
    onSuccess: () => {
      Navigate(`/member/${JSON.parse(uinfo).id}`);
      queryClient.invalidateQueries(["member", i18n.language]);
    },
  });
};

export const useAddGalleryImg = () => {
  const queryClient = useQueryClient();
  const Navigate = useNavigate();
  const uinfo = localStorage.getItem("uinf");

  return useMutation({
    mutationFn: addGalleryImg,
    onSuccess: () => {
      Navigate(`/member/${JSON.parse(uinfo).id}`);
      queryClient.invalidateQueries(["member", i18n.language]);
    },
  });
};

export const useGetMembersByFilter = (page) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: getMembersByFilter,
    onSuccess: (data) => {
      queryClient.setQueryData(["members", i18n.language], data);
    },
  });
};

export const useInteract = (uId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addInteract,
    onSuccess: () => {
      queryClient.refetchQueries(["members", i18n.language]);
      queryClient.refetchQueries([`member_view_${uId}`, i18n.language]);
    },
  });
};

export const useMemberNotices = () => {
  const uinfo = localStorage.getItem("uinf");

  return useQuery({
    queryKey: ["member-notices", JSON.parse(uinfo).id],
    queryFn: () => fetchMemberNotices(JSON.parse(uinfo).id),
    staleTime: twentyFourHoursInMs,
  });
};

export const useCloseAccount = () => {
  const queryClient = useQueryClient();
  const Navigate = useNavigate();
  return useMutation({
    mutationFn: closeAccount,
    onSuccess: () => {
      Navigate(`/`);
      queryClient.removeQueries();
      localStorage.clear();
    },
  });
};
