import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/layout/scrolltop";
import ProtectedRoute from "./ProtectedRoute";
import PreferencesInfo from "./pages/preferences";
import BaseInfo from "./pages/baseinfo";
import GallerySlider from "./component/slider/gallerySlider";
import FullScreenLoader from "./component/loaders/fullscreen";
import SearchContextProvider from "./context/searchContext";

const Home = lazy(() => import("./pages/home"));
const AboutPage = lazy(() => import("./pages/about"));
const ActivityPage = lazy(() => import("./pages/activity"));
const BlogPage = lazy(() => import("./pages/blog"));
const BlogDetails = lazy(() => import("./pages/blog-single"));
const BlogPageTwo = lazy(() => import("./pages/blogtwo"));
const ComingSoonPage = lazy(() => import("./pages/comingsoon"));
const EventsPage = lazy(() => import("./pages/events"));
const EventDetails = lazy(() => import("./pages/event-single"));
const ContactUs = lazy(() => import("./pages/contact"));
const ErrorPage = lazy(() => import("./pages/errorpage"));
const GroupPage = lazy(() => import("./pages/group"));
const GroupDetails = lazy(() => import("./pages/groupsingle"));
const LogIn = lazy(() => import("./pages/login"));
const MemberDetails = lazy(() => import("./pages/member-single"));
const MembersPage = lazy(() => import("./pages/members"));
const MembershipPage = lazy(() => import("./pages/membership"));
const ShopPage = lazy(() => import("./pages/shop"));
const ShopCart = lazy(() => import("./pages/shopcart"));
const ShopDetails = lazy(() => import("./pages/shopdetails"));
const SignUp = lazy(() => import("./pages/signup"));
const Policy = lazy(() => import("./pages/policy"));
const MemberDetailsView = lazy(() => import("./pages/member-single-view"));
const Terms = lazy(() => import("./pages/terms"));
const FAQ = lazy(() => import("./pages/faq"));
const Checkout = lazy(() => import("./pages/Checkout"));

function App() {
  return (
    <div className="App">
      <Suspense fallback={<FullScreenLoader size={200} center={true} />}>
        <ScrollToTop />

        <Routes>
          <Route
            path="/"
            element={
              <SearchContextProvider>
                <Home />
              </SearchContextProvider>
            }
          />

          <Route path="about" element={<AboutPage />} />
          <Route path="membership" element={<MembershipPage />} />
          <Route path="comingsoon" element={<ComingSoonPage />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="events" element={<EventsPage />} />
          <Route path="event/details/:id" element={<EventDetails />} />
          <Route path="group" element={<GroupPage />} />
          <Route path="group-single" element={<GroupDetails />} />
          <Route
            path="members"
            element={
              <SearchContextProvider>
                <ProtectedRoute>
                  <MembersPage />
                </ProtectedRoute>
              </SearchContextProvider>
            }
          />
          <Route path="activity" element={<ActivityPage />} />
          <Route path="shop" element={<ShopPage />} />
          <Route path="shop-single" element={<ShopDetails />} />
          <Route path="shop-cart" element={<ShopCart />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog-2" element={<BlogPageTwo />} />
          <Route path="blog-single" element={<BlogDetails />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="register" element={<SignUp />} />
          <Route path="login" element={<LogIn />} />
          <Route
            path="member/:id"
            element={
              <ProtectedRoute>
                <MemberDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="member/view/:id"
            element={
              <ProtectedRoute>
                <MemberDetailsView />
              </ProtectedRoute>
            }
          />
          <Route path="policy" element={<Policy />} />
          <Route
            path="preferences/:type"
            element={
              <ProtectedRoute>
                <PreferencesInfo />
              </ProtectedRoute>
            }
          />
          <Route
            path="baseinfo"
            element={
              <ProtectedRoute>
                <BaseInfo />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile/gallery"
            element={
              <ProtectedRoute>
                <GallerySlider />
              </ProtectedRoute>
            }
          />
          <Route path="terms" element={<Terms />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="checkout/:id" element={<Checkout />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
