import React from "react";
import TagsInput from "react-tagsinput";

const TagsTwo = ({ tags, setTags }) => {
  const handleChange = (tags) => {
    setTags(tags);
  };

  return (
    <TagsInput
      inputProps={{
        placeholder: "Add Tag",
      }}
      value={tags}
      onChange={handleChange}
      onlyUnique={true}
    />
  );
};

export default TagsTwo;
