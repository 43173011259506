import { createContext, useState } from "react";

const initalValues = {
  page: 1,
};

export const SearchContext = createContext(initalValues);

const SearchContextProvider = ({ children }) => {
  const [filter, setFilter] = useState(initalValues);

  return (
    <SearchContext.Provider value={{ filter, setFilter }}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
