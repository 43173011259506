import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import i18n from "../i18n";
import { twentyFourHoursInMs } from "../global";

const {
  REACT_APP_BASE_URL,
  REACT_APP_GET_ALL_COUNTRIES,
  REACT_APP_GET_COUNTRY_BY_ID,
  REACT_APP_GET_ALL_CITIES,
  REACT_APP_GET_CITY_BY_COUNTRY_ID,
} = process.env;

export const fetchCountries = (lng) => {
  return axios.get(REACT_APP_BASE_URL + REACT_APP_GET_ALL_COUNTRIES + lng);
};

export const fetchCountryById = (id, lng) => {
  return axios.get(
    REACT_APP_BASE_URL + REACT_APP_GET_COUNTRY_BY_ID + id + "/" + lng
  );
};

export const fetchCities = (lng) => {
  return axios.get(REACT_APP_BASE_URL + REACT_APP_GET_ALL_CITIES + lng);
};

export const fetchCitiesByCountryId = (id) => {
  return axios.get(
    REACT_APP_BASE_URL +
      REACT_APP_GET_CITY_BY_COUNTRY_ID +
      id +
      "/" +
      i18n.language
  );
};

export const useCountries = () => {
  return useQuery({
    queryKey: ["countries", i18n.language],
    queryFn: () => fetchCountries(i18n.language),
    staleTime: twentyFourHoursInMs,
  });
};

export const useCities = () => {
  return useQuery({
    queryKey: ["cities", i18n.language],
    queryFn: () => fetchCities(i18n.language),
    staleTime: twentyFourHoursInMs,
  });
};

export const useCitiesByCountryId = (id) => {
  return useQuery({
    queryKey: ["cities-" + id, i18n.language],
    queryFn: () => fetchCitiesByCountryId(id, i18n.language),
    staleTime: twentyFourHoursInMs,
  });
};
