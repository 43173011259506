import { useMember } from "../../apis/Member";

import { useEffect, useState } from "react";

import Lightbox, { useLightboxState } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const GallerySlider = ({ selected, data, isGalleryOpen, galleryOpen }) => {
  const { REACT_APP_IMG_URL } = process.env;
  const [slidesData, setSlidesData] = useState([]);

  useEffect(() => {
    data.map((d) => {
      setSlidesData((current) => [
        ...current,
        { src: REACT_APP_IMG_URL + d.large },
      ]);
    });
  }, [data]);

  return (
    <>
      <Lightbox
        open={isGalleryOpen}
        close={() => galleryOpen(false)}
        slides={slidesData}
        index={selected}
      />
    </>
  );
};

export default GallerySlider;
